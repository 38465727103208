const BASE_URL = 'https://www.qingju.com.cn/';

const INTRODUCE_INFO = {
    home:{
        title: '西安青橘信息技术有限公司',
        description: '西安青橘信息技术有限公司',
        keywords: '西安青橘信息技术有限公司',
    },
    introduce:{
        title: '公司介绍',
        description: '西安青橘信息技术有限公司',
        keywords: '西安青橘信息技术有限公司',
    },
    product:{
        title: '产品方案',
        description: '西安青橘信息技术有限公司',
        keywords: '西安青橘信息技术有限公司',
    },
    service:{
        title: '技术服务',
        description: '西安青橘信息技术有限公司',
        keywords: '西安青橘信息技术有限公司',
    },
    news:{
        title: '新闻消息',
        description: '西安青橘信息技术有限公司',
        keywords: '西安青橘信息技术有限公司',
    },
    contact:{
        title: '联系我们',
        description: '西安青橘信息技术有限公司',
        keywords: '西安青橘信息技术有限公司',
    }
};

export default {
    BASE_URL,
    INTRODUCE_INFO
}