import { createRouter, createWebHistory } from "vue-router";


const routes = [
	//后端推送，这个没用，只是能消除一个前端告警。
	// {
	// 	path:'/',
	// },
]

const router = createRouter({
	// createWebHistory路由模式路径不带#号
	history: createWebHistory(),
	routes
});

//解决两次访问相同路由地址报错
// const router_push = router.prototype.push
// router.prototype.push = function push(location) {
//     return router_push.call(this, location).catch(error=> error)
// }

export default router;