<!--页脚-->
<template>


	<div class="mt-4 pt-4" style="background: rgba(0, 0, 126, 0.8); font-size: 18px;">
		
		<!-- Bootstrap4 Flex（弹性）柔性布局 -->
		<!-- .justify-content-* 类用于修改弹性子元素的排列方式，* 号允许的值有：start (默认), end, center, between 或 around: -->
		<!-- 这里mx-3是为了避免手机上打开后，和手机框太紧。 -->
		<div class="mx-3 d-flex justify-content-around">

			<div class="">
				<a href="http://beian.miit.gov.cn/" class="text-white" target="_blank">
					陕ICP备19001223号-2
				</a>
			</div>

			<div class="">
				<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002000871" class="text-white" target="_blank">
					<img src="../assets/img/WechatIMG136.png" style="float:left;" />
					陕公网安备61019002000871号
				</a>
			</div>

			<div class="">
				<!-- 这样通过前端引用的图片需要手动考过去 -->
				<a href="/static/img/zizhi_zengzhidianxinyewujingying.jpg" class="text-white" target="_blank">
					增值电信业务经营许可证 陕B2-20190112
				</a>
			</div>

		</div>
		

		
		<div class="mt-5" align="center">
			<p class="text-white">© 2018-现在 <a href="https://www.qingju.com.cn/" class="text-white" target="_blank">青橘公司</a> 版权所有</p>
		</div>


		<div align="center">
			<img src="../assets/img/qrcode.png" width="200" height="200" />
			<br>
			<p class="text-white my-1">青橘公司网址二维码-欢迎保存分享</p>
		</div>
		
	</div>


</template>

<script>
    export default {
        data() {
            return {
                year: ''
            }
        },
        computed: {

        }

    }
</script>