<!--页眉-->
<template>

	<!--heade_section：style.css中使用。-->
	<div id="header_section">
		<!--overlay：style.css中使用。-->
		<div class="overlay">
			<div class="row">

				<!--bootstrap网格列是通过跨越指定的 12 个列来创建。分了5列。-->
				<div class="col-md-2"></div>

				<!-- 这里m-2是为了避免手机上打开后，和手机框太紧。 -->
				<!--bootstrap的margin： 和padding mb-3：m，margin外边距。p，padding内填充。t,b,s,e,x,y含义分别是top,bottom,left,right,mplf一起,mpbt一起。最大5-->
				<div class="col-md-1 m-2">
					<img src="../assets/img/logo.png" alt="西安青橘信息技术有限公司logo" class="img-fluid" width="100" height="100">
				</div>
				<div class="col-md-4 m-2 ps-0 pt-3">
					<h1 class="text-dark">西安青橘信息技术有限公司</h1>
					<h3 class="text-dark">Xi'an Qingju Information Technology Co., Ltd.</h3>
				</div>

				<!-- 将来这里可以放企业微信 -->
				<div class="col-md-5"></div>

			</div>
		</div>
	</div>


</template>
<script>
    export default {
        data() {
            return {

            }
        },

        computed: {

        },

    };
</script>