<!--bootstrap4导航栏。-->

<template>

    <div id="navbar_section">

        <!--navbar：bootstrap导航栏。-->
        <!--navbar-expand-lg：bootstrap网格类。bootstrap4中是弹性格子了。-->
        <!--navbar-dark：白字。-->
        <!--fixed-top：固定在头部。-->
        <!--mb-3：m，margin外边距。p，padding内填充。t,b,s,e,x,y含义分别是top,bottom,left,right,lf一起,bt一起。最大5-->
		<!-- navbar-expand-sm，用来在小屏幕上展开为竖展开导航。 -->
        <nav class="navbar navbar-expand-sm navbar-dark" id="main-nav">

			<!-- 这里用个container是为了让导航栏的外部颜色bg-primary全宽度，而内部的选项内容在中间，两边空开宽度。 -->
			<div class="container whole_min_width">
				
				<!--折叠导航栏按钮-->
				<!--要创建折叠导航栏，可以在按钮上添加 class="navbar-toggler", data-toggle="collapse" 与 data-target="#thetarget" 类。-->
				<!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#id_navbar_toggler">
					<span class="navbar-toggler-icon"></span>
				</button> -->

				<!--折叠导航栏按钮支持-->
				<!--然后在设置了 class="collapse navbar-collapse" 类的 div 上包裹导航内容（链接）, div 元素上的 id 匹配按钮 data-target 的上指定的 id-->
				<!-- <div class="collapse navbar-collapse" id="id_navbar_toggler"> -->

					<!--ul无序列表，li是列表项-->
					<!--导航栏上的选项可以使用 <ul> 元素并添加 class="navbar-nav" 类。 然后在 <li> 元素上添加.nav-item 类， <a> 元素上使用.nav-link 类-->
					<ul class="navbar-nav">

						<li class="nav-item">
							<!-- https://www.jb51.net/article/243491.htm -->
							<a :class='["nav-link", page_index==="100"?"active":""]' @click="change_index('100')" href="/">
								首页
							</a>

						</li>

						<li class="nav-item ps-4">
							<a :class='["nav-link", page_index==="110"?"active":""]' @click="change_index('110')" href="/introduce">
								公司介绍
							</a>
						</li>

						<!--dropdown：导航栏用下拉菜单。-->
						<li class="nav-item ps-4 dropdown">
							<a :class='["nav-link dropdown-toggle", (page_index==="120")||(page_index==="121")||(page_index==="122")||(page_index==="123")||(page_index==="124")?"active":""]' @click="change_index('120')" href="#" data-toggle="dropdown">
								产品方案
							</a>
							<div class="dropdown-menu">
								<a class="dropdown-item" @click="change_index('121')" href="/product_uav">无人机数传</a>
								<a class="dropdown-item" @click="change_index('122')" href="/product_satellite">卫星通信</a>
								<a class="dropdown-item" @click="change_index('123')" href="/product_sw">短波设备</a>
								<a class="dropdown-item" @click="change_index('124')" href="/product_other">其它</a>
							</div>
						</li>

						<li class="nav-item ps-4">
							<a :class='["nav-link", page_index==="130"?"active":""]' @click="change_index('130')" href="/service">
								技术服务
							</a>
						</li>

						<li class="nav-item ps-4">
							<a :class='["nav-link", page_index==="140"?"active":""]' @click="change_index('140')" href="/news">
								新闻消息
							</a>
						</li>

						<li class="nav-item ps-4">
							<a :class='["nav-link", page_index==="150"?"active":""]' @click="change_index('150')" href="/contact">
								联系我们
							</a>
						</li>

					</ul>
				
				<!-- 折叠导航栏不用了 -->
				<!-- </div> -->

			</div>

        </nav>

    </div>

</template>



<script>

    export default {

        data() {
            return {
                page_index: '100',

                res: '',
            }
        },
        //在实例创建完成后被立即调用。
        created() {

        },
        //el 被新创建的 vm.$el 替换，并挂载到实例上去之后调用该钩子。
        mounted() {
            if (sessionStorage.getItem('page_index')) {
                this.page_index = sessionStorage.getItem('page_index');
            } else {
                this.page_index = '100';
            }
            console.log("mounted page_index: " + this.page_index)

        //     var self = this;
        //     if(self.$store.state.user_info.state==='success'){

        //         const config = {
        //             headers: {
        //                 'Authorization': 'Bearer ' + localStorage.getItem("access_token")
        //             }
        //         };
        //         var send_data = {};
        //         send_data['user_id'] = self.$store.state.user_info.id;
        //         self.$axios.post(self.$global.BASE_URL + 'api/if_user_oamer/', send_data, config)
        //             .then(res => {
        //                 if (res.data.result === -1) {
        //                     self.if_oamer = -1;
        //                 } else {
        //                     self.if_oamer = res.data.oam_user;
        //                 }
        //             }).catch(err => {
        //             self.res = err;
        //         });

        //     }
		},
        //computed属性，只要值变化就会调用。
        computed: {
            // computed_if_oamer(){
            //     //如果用户登录，并且只有三个手机可以进入。
            //     return this.if_oamer;
            // }
        },

        methods: {
            change_index(index) {
                this.page_index = index;
                //最主要的作用，就在于不管处于什么原因展开navbar的时候，都可以调用上次储存的page_index。
                sessionStorage.setItem('page_index', index);
            },
			

        }
    }
</script>